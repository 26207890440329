@font-face {
  font-family: 'Aeonik Pro Air';
  src: url('AeonikPro-Air.woff2') format('woff2'),
    url('AeonikPro-Air.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro Air';
  src: url('AeonikPro-AirItalic.woff2') format('woff2'),
    url('AeonikPro-AirItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro Thin';
  src: url('AeonikPro-Thin.woff2') format('woff2'),
    url('AeonikPro-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro Thin';
  src: url('AeonikPro-ThinItalic.woff2') format('woff2'),
    url('AeonikPro-ThinItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro Light';
  src: url('AeonikPro-Light.woff2') format('woff2'),
    url('AeonikPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro Light';
  src: url('AeonikPro-LightItalic.woff2') format('woff2'),
    url('AeonikPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro';
  src: url('AeonikPro-Regular.woff2') format('woff2'),
    url('AeonikPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro';
  src: url('AeonikPro-RegularItalic.woff2') format('woff2'),
    url('AeonikPro-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro Medium';
  src: url('AeonikPro-Medium.woff2') format('woff2'),
    url('AeonikPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro Medium';
  src: url('AeonikPro-MediumItalic.woff2') format('woff2'),
    url('AeonikPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro';
  src: url('AeonikPro-Bold.woff2') format('woff2'),
    url('AeonikPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro';
  src: url('AeonikPro-BoldItalic.woff2') format('woff2'),
    url('AeonikPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro Black';
  src: url('AeonikPro-Black.woff2') format('woff2'),
    url('AeonikPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Pro Black';
  src: url('AeonikPro-BlackItalic.woff2') format('woff2'),
    url('AeonikPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
