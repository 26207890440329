/* inconsolata-200 - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 200;
  src: url('inconsolata-v21-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inconsolata-v21-latin-200.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('inconsolata-v21-latin-200.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inconsolata-v21-latin-200.woff')
      format('woff'),
    /* Modern Browsers */ url('inconsolata-v21-latin-200.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('inconsolata-v21-latin-200.svg#Inconsolata')
      format('svg'); /* Legacy iOS */
}
/* inconsolata-300 - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 300;
  src: url('inconsolata-v21-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inconsolata-v21-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('inconsolata-v21-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inconsolata-v21-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('inconsolata-v21-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('inconsolata-v21-latin-300.svg#Inconsolata')
      format('svg'); /* Legacy iOS */
}
/* inconsolata-regular - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: url('inconsolata-v21-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inconsolata-v21-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('inconsolata-v21-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inconsolata-v21-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('inconsolata-v21-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('inconsolata-v21-latin-regular.svg#Inconsolata') format('svg'); /* Legacy iOS */
}
/* inconsolata-500 - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 500;
  src: url('inconsolata-v21-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inconsolata-v21-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('inconsolata-v21-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inconsolata-v21-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('inconsolata-v21-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('inconsolata-v21-latin-500.svg#Inconsolata')
      format('svg'); /* Legacy iOS */
}
/* inconsolata-600 - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 600;
  src: url('inconsolata-v21-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inconsolata-v21-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('inconsolata-v21-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inconsolata-v21-latin-600.woff')
      format('woff'),
    /* Modern Browsers */ url('inconsolata-v21-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('inconsolata-v21-latin-600.svg#Inconsolata')
      format('svg'); /* Legacy iOS */
}
/* inconsolata-700 - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  src: url('inconsolata-v21-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inconsolata-v21-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('inconsolata-v21-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inconsolata-v21-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('inconsolata-v21-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('inconsolata-v21-latin-700.svg#Inconsolata')
      format('svg'); /* Legacy iOS */
}
/* inconsolata-800 - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 800;
  src: url('inconsolata-v21-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inconsolata-v21-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('inconsolata-v21-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inconsolata-v21-latin-800.woff')
      format('woff'),
    /* Modern Browsers */ url('inconsolata-v21-latin-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('inconsolata-v21-latin-800.svg#Inconsolata')
      format('svg'); /* Legacy iOS */
}
/* inconsolata-900 - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 900;
  src: url('inconsolata-v21-latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('inconsolata-v21-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('inconsolata-v21-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('inconsolata-v21-latin-900.woff')
      format('woff'),
    /* Modern Browsers */ url('inconsolata-v21-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('inconsolata-v21-latin-900.svg#Inconsolata')
      format('svg'); /* Legacy iOS */
}
