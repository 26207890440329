@media (prefers-color-scheme: dark) {
  .dittoCalendar {
    border-color: #2c2a2a !important;
    overflow: hidden;
  }

  .dittoCalendar.react-datepicker {
    background-color: #1d1b1b;
  }

  .dittoCalendar .react-datepicker__month-container,
  .dittoCalendar .react-datepicker__time {
    background-color: #1d1b1b;
    color: #f5f3ef;
  }

  .dittoCalendar .react-datepicker__day,
  .dittoCalendar .react-datepicker__current-month,
  .dittoCalendar .react-datepicker__day-name,
  .dittoCalendar .react-datepicker-time__header {
    color: #f5f3ef;
  }

  .dittoCalendar .react-datepicker__day:hover {
    background-color: #222020;
  }

  .dittoCalendar .react-datepicker__time-list-item:hover {
    background-color: #222020 !important;
  }

  .dittoCalendar .react-datepicker__header {
    background-color: transparent;
  }

  .dittoCalendar .react-datepicker__triangle::after {
    border-bottom-color: #2c2a2a !important;
  }

  .dittoCalendar .react-datepicker__day {
    background-color: #1d1b1b;
  }

  .dittoCalendar .react-datepicker__day--disabled {
    color: #2c2a2a !important;
    cursor: not-allowed;
  }

  .dittoCalendar .react-datepicker__day--disabled:hover {
    background-color: #1d1b1b;
  }

  .dittoCalendar .react-datepicker__time-container {
    border-color: #2c2a2a !important;
  }

  .dittoCalendar .react-datepicker__day--in-range {
    background-color: #222020;
  }

  .dittoCalendar .react-datepicker__day--in-selecting-range {
    background-color: #222020;
  }

  .dittoCalendar .react-datepicker__day--outside-month {
    color: #a8a6a3;
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
}

@media (prefers-color-scheme: light) {
  .dittoCalendar {
    border-color: #d4d4d4 !important;
    overflow: hidden;
  }

  .dittoCalendar .react-datepicker__month-container {
    background-color: #ece9e4;
    color: #171717;
  }

  .dittoCalendar .react-datepicker__day {
    background-color: #ffffff;
    color: #171717;
  }

  .dittoCalendar .react-datepicker__day:hover {
    background-color: #f9f8f6;
  }

  .dittoCalendar .react-datepicker__day--in-range {
    background-color: #f9f8f6;
  }

  .dittoCalendar .react-datepicker__day--in-selecting-range {
    background-color: #f9f8f6;
  }

  .dittoCalendar .react-datepicker__header {
    background-color: #ffffff;
  }

  .dittoCalendar .react-datepicker__triangle::after {
    border-bottom-color: #ece9e4 !important;
  }

  .dittoCalendar .react-datepicker__day {
    background-color: #ffffff;
  }

  .dittoCalendar .react-datepicker__day--disabled {
    color: #f1eee9 !important;
    cursor: not-allowed;
  }

  .dittoCalendar .react-datepicker__day--outside-month {
    color: #757270;
  }
}

.customInput .react-datepicker__input-container {
  display: flex;
}

.dittoCalendar .react-datepicker__navigation--next {
  /* top: 10px; */
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.dittoCalendar .react-datepicker__navigation--previous {
  /* top: 10px; */
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.dittoCalendar .react-datepicker__header {
  padding: 0 !important;
  border: none !important;
}

.dittoCalendar .react-datepicker__current-month {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;

  height: 40px;
  display: inline-flex;
  align-items: center;
}

.dittoCalendar .react-datepicker__month-container,
.dittoCalendar .react-datepicker__time-container {
  border: none !important;
  border-bottom: none !important;
  border-radius: 0;
  overflow: hidden;
  margin: 0 !important;
}

.dittoCalendar .react-datepicker__header {
  border-bottom: none !important;
}

.dittoCalendar .react-datepicker__month {
  margin: 0 !important;
}

.dittoCalendar .react-datepicker__day {
  height: 40px !important;
  width: 40px !important;
  margin: 0 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 0 !important;
  font-family: IBM Plex Mono;
  position: relative;
  z-index: 5;
}

.dittoCalendar .react-datepicker__day--selected,
.dittoCalendar .react-datepicker__day--selected:hover,
.dittoCalendar .react-datepicker__day--range-end,
.dittoCalendar .react-datepicker__day--range-end:hover,
.dittoCalendar .react-datepicker__day--selecting-range-end,
.dittoCalendar .react-datepicker__day--selecting-range-end:hover {
  background-color: #2c7be5;
  color: #fff !important;
}

.dittoCalendar .react-datepicker__day-name {
  height: 40px !important;
  width: 40px !important;
  margin: 0 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 0 !important;
}

.dittoCalendar.react-datepicker {
  border-radius: 0;
}

input[type='time']::-webkit-calendar-picker-indicator {
  padding-right: 0;
}

input[type='time']::-webkit-clear-button {
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  margin: -10px;
}
