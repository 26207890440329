@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 100;
  src: url('IBMPlexMono-Thin.woff2') format('woff2'),
    url('IBMPlexMono-Thin.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 100;
  src: url('IBMPlexMono-ThinItalic.woff2') format('woff2'),
    url('IBMPlexMono-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 200;
  src: url('IBMPlexMono-ExtraLight.woff2') format('woff2'),
    url('IBMPlexMono-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 200;
  src: url('IBMPlexMono-ExtraLightItalic.woff2') format('woff2'),
    url('IBMPlexMono-ExtraLightItalic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  src: url('IBMPlexMono-Light.woff2') format('woff2'),
    url('IBMPlexMono-Light.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  src: url('IBMPlexMono-LightItalic.woff2') format('woff2'),
    url('IBMPlexMono-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: url('IBMPlexMono-Regular.woff2') format('woff2'),
    url('IBMPlexMono-Regular.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  src: url('IBMPlexMono-Italic.woff2') format('woff2'),
    url('IBMPlexMono-Italic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  src: url('IBMPlexMono-Medium.woff2') format('woff2'),
    url('IBMPlexMono-Medium.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 500;
  src: url('IBMPlexMono-MediumItalic.woff2') format('woff2'),
    url('IBMPlexMono-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  src: url('IBMPlexMono-SemiBold.woff2') format('woff2'),
    url('IBMPlexMono-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  src: url('IBMPlexMono-SemiBoldItalic.woff2') format('woff2'),
    url('IBMPlexMono-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  src: url('IBMPlexMono-Bold.woff2') format('woff2'),
    url('IBMPlexMono-Bold.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 700;
  src: url('IBMPlexMono-BoldItalic.woff2') format('woff2'),
    url('IBMPlexMono-BoldItalic.woff') format('woff');
}

.plex-mono {
  font-family: 'IBM Plex Mono', monospace;
}
